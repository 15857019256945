.UD3-container {
  width: 250px;
  height: 150px;
}

.projectInvite--container {
  padding: 5px;
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f8f8f8;
  border-radius: 5px;
}

.UD3--buttonContainer {
  position: relative;
  margin: 5px;
}
