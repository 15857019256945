.chatBox__container {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: blue;
  width: 300px;
  height: 400px;
  z-index: 100;
  bottom: 5vh;
  right: 10vw;
}

.chatIcon {
  position: fixed;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  bottom: 5vh;
  right: 5vw;
  font-size: 100px;
  border-radius: 25px;
}

.chatBox__header {
  width: 300px;
  height: 35px;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
}

.chatBox__messageList__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  height: 365px;
  padding-top: 5px;
}
.chatBox__messageList {
  width: 300px;
  height: 310px;
}
.chatBox__inputBox {
  border: 1px solid lightgray;
}
