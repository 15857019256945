.home3--container {
  width: 95vw;
  margin: 0 2.5vw;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.home3--insideContainer1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 450px;
  background-color: #fff;
}

.home3--insideContainer1 > img {
  width: 90%;

  max-height: 85%;
  display: block;
  object-fit: cover;
}

.home3--insideContainer1 > h1 {
  text-align: right;
  margin-top: -75px;

  align-self: flex-end;
  padding: 0 20px;
  margin-right: 15vw;
  font-size: 35px;
  background-color: #fff;
}

.home3--insideContainer2 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.home3--insideContainer2--card {
  margin: 5px;
  height: 450px;
  border-radius: 25px;
}

.home3--insideContainer2 > div {
  display: flex;
  flex-direction: column;
  width: 30%;
  min-width: 350px;
  align-items: center;
  justify-content: center;
}

.home3--insideContainer2 > div > img {
  height: 35%;
  color: blue;
}
.home3--insideContainer2 > div > h1 {
  margin-top: 10px;
  font-size: 25pxs;
  color: blue;
}

.home3--insideContainer2 > div > p {
  font-size: larger;
  padding: 25px;
  text-align: justify;
}

.home3--btn {
  color: blue;
  background-color: #fff;
  border-color: blue;
  padding: 12px 35px;
  margin: 30px;
  font-size: 20px;
  align-self: center;
  transition: 1s;
}

.home3--btn:hover {
  background: black;
  color: white;
  transition: all 0.3s ease-out;
}
