.signup--container {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: lightgrey;
}

.signup--insideContainer {
  width: 100%;
  height: 100%;
  max-width: 85%;
  min-height: 100vh;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.signup--insideContainer1 {
  width: 50%;
  min-width: 380px;
  padding: 15px;
  margin-top: 15vh;
}

.signup--insideContainer2 {
  width: 30%;
  min-width: 380px;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  display: flex;
  padding: 15px 20px;
  height: 750px;
  margin-top: 25px;
}

.signup--insideContainer2-headerText {
}

.signup--insideContainer2-textInput1 {
  width: 100%;
  margin: 50px;
  background-color: #fff;
}

.signup--topText {
  color: lightgray;
  font-weight: lighter;
  font-size: 35px;
  text-decoration-color: lightgreen;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
  font-weight: 100;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;

  word-spacing: 5px;
}

@media screen and (max-width: 1380px) {
  .signup--insideContainer {
    width: 100%;
    height: 100%;
    max-width: 90%;
    min-height: 100vh;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .signup--topText {
    color: lightgray;
    font-weight: lighter;
    font-size: 30px;
    text-decoration-color: lightgreen;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
    font-weight: 100;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;

    word-spacing: 5px;
  }
}

@media screen and (max-width: 960px) {
  .signup--topText {
    color: lightgray;
    font-weight: lighter;
    font-size: 25px;
    text-decoration-color: lightgreen;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
    font-weight: 100;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;

    word-spacing: 5px;
  }
}
