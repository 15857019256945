:root {
  color: #293845;
}

.home2--insideContainer1 {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  margin: 5px 5px;
}

.home2--insideContainer1 > h1 {
  width: 70%;
  padding-bottom: 20px;
  font-weight: 1500;
  font-style: italic;
}

.home2--insideContainer1 > p {
  color: #595959;
  width: 90%;
  font-size: x-large;
  font-weight: 1000;
}

.home2--insideContainer1 > img {
  width: 90%;
  max-height: 40%;
  display: block;
  object-fit: cover;
  padding-top: 20px;
}

.home2--insideContainer2 {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 30px;
  margin: 5px 5px;
}

.home2--insideContainer2 > p {
  color: #595959;
  width: 90%;
  font-size: x-large;
  margin: 30px;
}

.home2--button {
  color: #fff;
  background-color: blue;
  border-color: blue;
  padding: 12px 26px;
  margin-top: 50px;
  font-size: 20px;
}

.home2--button:hover {
  color: black;
  background-color: #fff;
  padding: 12px 26px;
  margin-top: 50px;
  font-size: 20px;
  transition: all 0.3s ease-out;
}

.home1--btn:hover {
  background: #fff;
  color: #242424;
  transition: all 0.3s ease-out;
}

.home2--insideContainer2--text1 {
  align-self: flex-start;
  text-align: left;
  font-style: italic;
  font-size: 35px;
}
.home2--insideContainer2--text2 {
  align-self: flex-start;
  text-align: right;
  font-style: italic;
  font-size: 35px;
}

@media screen and (max-width: 768px) {
  .home2--insideContainer1 > h1 {
    width: 70%;
    padding-bottom: 20px;
    font-weight: 1500;
    font-style: italic;
    font-size: 25px;
  }

  .home2--insideContainer1 > p {
    color: #595959;
    width: 90%;
    font-size: 20px;
    font-weight: 1000;
  }

  .home2--insideContainer2 > p {
    color: #595959;
    width: 90%;
    font-size: 20px;
    margin: 30px;
  }
  .home2--insideContainer2--text1 {
    align-self: flex-start;
    text-align: left;
    font-style: italic;
    font-size: 25px;
  }
  .home2--insideContainer2--text2 {
    align-self: flex-start;
    text-align: right;
    font-style: italic;
    font-size: 25px;
  }
}
