.messenger__container {
  display: flex;
  flex: 1;
}

.messenger__chalList__container {
  min-width: 150px;
  height: 90vh;
  overflow-y: scroll;
  width: 25vw;
  background-color: #ecf2f9;
  border: 1px solid lightgray;
}

.messenger__messageList__container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 90vh;
  background-color: #f1f1f1;
}

.messenger__messageList {
  width: 100%;
  min-width: 300px;
  height: 85vh;
  max-height: 85vh;
  overflow-y: scroll;
}

.messenger__inputBox {
}
