.home4--container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 25px;
  margin-bottom: 25px;
}

.home4--conatiner--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 65px;
}

.home4__cards__items {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

 {
  /*Card Styles*/
}

.home4__cards__item__link {
  width: 350px;
  height: 250px;
  border-radius: 10px;
  padding: 20px;
  margin: 15px;
  margin-top: 5px;
}

.home4__cards__item__link > div {
  background-color: lightyellow;
  width: 100%;
  height: 85px;
}

.home4__cards__item__img {
  width: 100%;
  min-width: 330px;
  height: 140px;
  object-fit: cover;
}

.home4--btn {
  color: blue;
  background-color: #fff;
  border-color: blue;
  padding: 12px 35px;
  margin: 30px;
  font-size: 20px;
}

.home4--btn:hover {
  background: black;
  color: white;
  transition: all 0.3s ease-out;
}
