video {
  object-fit: cover;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}
.home1 {
  width: 100%;
}
.home1--button1--div {
  margin-top: 30px;
}

.home1--button1 {
  font-size: 35px;
  margin-top: 50px;
}
.home1--container {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home1--container > img {
  z-index: -1;
  object-fit: cover;
}
.home1--insideContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home1--insideContainer > h1 {
  color: #fff;
  font-size: 55px;
  max-width: 65vw;
}

.home1--btn {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  padding: 12px 26px;
  font-size: 20px;
  margin-top: 15%;
}

.home1--btn:hover {
  background: #fff;
  color: #242424;
  transition: all 0.3s ease-out;
}
.home__text {
  display: none;
}

@media screen and (max-width: 768px) {
  .home1--container {
    width: 100%;
    height: 450px;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .home1--insideContainer > h1 {
    font-size: 30px;
    margin-top: -100px;
  }
  .home1--btn {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    padding: 12px 26px;
    font-size: 15px;
    margin-top: 15%;
  }
  .home__text {
    display: block;
    width: 100%;
    font-size: 35px;
    text-align: center;
    align-self: center;
  }
}
