.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.top-text-section {
  background-color: #96c3ec;
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  background: blue;
}

.top-text-section > h1 {
  color: #293845;
  font-size: 50px;
  font-style: italic;
  font-weight: lighter;
}
.ci-12 {
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 350px;
}

.container > h2 {
  position: absolute;
  color: #fff;
  top: 470px;
  font-size: 40px;
  max-width: 1100px;
  text-overflow: wrap;
  text-align: center;
  font-style: italic;
  z-index: 1;
  margin-top: 0 30px;
}

.about1-inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
  margin: 30px;
  border-radius: 100px;
}

.about1-inner-container > h1 {
  padding-top: 30px;
  font-size: 50px;
}

.about1-inner-container > p {
  padding: 30px 55px;
  font-size: x-large;
  text-align: justify;
}
.ci-12 > h2 {
  color: #fff;
  font-size: 55px;
  font-style: italic;
  font-weight: lighter;
  text-align: center;
  justify-self: center;
  align-self: center;
}

@media screen and (max-width: 768px) {
  .top-text-section > h1 {
    color: #293845;
    font-size: 30px;
    font-style: italic;
    font-weight: lighter;
    text-align: center;
  }
  .ci-12 > h2 {
    color: #fff;
    font-size: 30px;
    font-style: italic;
    font-weight: lighter;
    text-align: center;
    justify-self: center;
    align-self: center;
  }

  .about1-inner-container > h1 {
    padding-top: 30px;
    font-size: 30px;
    text-align: center;
  }
  .about1-inner-container > p {
    padding: 10px 10px;
    font-size: 23px;
    text-align: justify;
  }
}
