.login--container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login--insideContainer1 {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}

.login--logo {
  max-width: 100px;
  height: auto;
}
.login--topText {
  color: #fff;
  font-weight: 100;
}

.login--insideContainer2 {
  width: 30%;
  min-width: 380px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  display: flex;
  padding: 15px;
  height: 550px;
  margin-top: 25px;
  margin-bottom: 50px;
}

.login--insideContainer2--headerText {
  align-self: center;
  font-weight: bold;
}

.login--insideContainer2-headerText {
}

.login--insideContainer2-textInput1 {
  width: 100%;
  margin: 50px;
}
