* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

:root {
  background-color: #f8f8f8;
}

@media screen and (max-width: 960px) {
  :root {
    font-size: 14px;
  }
}
