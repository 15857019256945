.servicesCard--cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 250px;
  width: 350px;
  margin: 20px;
  z-index: 0;
  border-radius: 50px;
}

.servicesCard--cardImage {
  background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5));
  height: 250px;
  width: 350px;
  object-fit: cover;
  margin: 5px;
  border-radius: 5px;
  opacity: 0.9;
}

.servicesCard--cardContainer > h1 {
  width: 350px;
  height: 75px;
  margin-top: -85px;
  padding-top: 10px;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  background-color: #261a0d;
  opacity: 0.9;
  z-index: 1;
}

.servicesCard--cardImage:hover {
  height: 250px;
  width: 350px;
  object-fit: cover;
  margin: 5px;
  border-radius: 5px;
  opacity: 1;
  transition: 0.3s;
}
